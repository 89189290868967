import React, {useState} from 'react';
import "./Slider.scss";

import {gallery, sliderImages} from './gallery';
import BtnSlider from './BtnSlider';
import Title from "../Title/Title";

import ImageGallery from 'react-image-gallery';


interface slider {
  index: number;
  inProgress:  boolean;
}
interface Props {
	className?: string;
	// ariaHidden: boolean;
	data: sliderImages[];
}

const Slider:React.FC<Props> = ({className, data}) => {

	// State to know the index of the picture to display
	const [slideAnim, setSlideAnim] = useState<slider>({
		index: 1,
		inProgress: false
	})

	// Functions when the user click on the left or right buttons
	const nextSlide = ():void => {

		if(slideAnim.index !== data.length && !slideAnim.inProgress) {

			setSlideAnim({index: slideAnim.index + 1,inProgress: true})

			setTimeout(() => {
				setSlideAnim({index: slideAnim.index + 1, inProgress: false})
			}, 400)
		}
		else if(slideAnim.index === data.length && !slideAnim.inProgress) {

				setSlideAnim({index: 1,inProgress: true})

				setTimeout(() => {
				setSlideAnim({index: 1, inProgress: false})
			}, 400)
		}
	}
	const prevSlide = ():void => {

		if(slideAnim.index !== 1 && !slideAnim.inProgress) {

			setSlideAnim({index: slideAnim.index - 1, inProgress: true})

			setTimeout(() => {
				setSlideAnim({index: slideAnim.index - 1, inProgress: false})
			}, 400)
		}
		else if(slideAnim.index === 1 && !slideAnim.inProgress) {

				setSlideAnim({index: data.length, inProgress: true})

				setTimeout(() => {
				setSlideAnim({index: data.length, inProgress: false})
			}, 400)
		}
	}

	console.debug('slideAnim: ', slideAnim)

	return (

			// <div className={`gallery ${className}`} >



				<div className='gallery__slider'>
					{/*<ImageGallery items={images} />*/}

					{data.map((obj, index) => {
						return (
							<div key={index}
								className={slideAnim.index === index + 1 ?
											"slide active-anim"
											:
											"slide"
								}>

								<img src={obj.url} alt={`${obj}`} />
							</div>
						)
					})}

					<BtnSlider moveSlide={prevSlide} direction={"prev"} tabIndex={0} />
					<BtnSlider moveSlide={nextSlide} direction={"next"} tabIndex={0} />
				</div>
			// </div>
	)
};

export default Slider;
