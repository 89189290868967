import React, {ReactElement} from 'react';
import {gallery} from '../Components/Slider/gallery'
import Slider from '../Components/Slider/Slider'
import Title from '../Components/Title/Title'

export interface ContactProps  {
  className: string;
  ariaHidden: boolean;
}

function Gallery({className, ariaHidden}:ContactProps):ReactElement {

  return (
    <div className={`gallery ${className}`} >
      <Title title="Galerie" className='gallery__title' ariaHidden={ariaHidden}/>
        <Slider data={gallery} />
    </div>
  )
};

export default Gallery;

