import React from 'react';
import "./ImageSection.scss";

// Interface for the cmponent props
export interface Props  {
	className: string;
	src: string;
	alt: string;
	page?: string
}

const ImageSection:React.FC<Props> = ({className, src, alt, page}) => {

  	return (
	  	<div className={`${className} image ${page === "parcours" ? "parcours" : "other"}`}>
				<img src={src} alt={alt} />
		</div>
  	)
};

export default ImageSection;
