export interface sliderImages {
	title: string;
	subtitle:  string;
	url: string;
}

export const gallery:sliderImages[] = [
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/img5.webp`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/img1.webp`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/img2.webp`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/img4.webp`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/img7.webp`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/img6.webp`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/img3.webp`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/img8.webp`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/img10.webp`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/img11.webp`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/img12.webp`,
	},
]
export const localisation:sliderImages[] = [
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/image2.webp`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/image.webp`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/image6.webp`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/image4.webp`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/image3.jpg`,
	},
	{
		title: "Lorem Ipsum",
		subtitle: "Lorem",
		url: `${process.env.PUBLIC_URL}/assets/sliderImgs/image5.webp`,
	},
]