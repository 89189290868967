import React, { ReactElement } from 'react';
import "./Contact.scss";

import Title from '../../Components/Title/Title';
import ContactForm from '../../Components/Form/Form';
import Map from '../../Components/Map/Map';

import { FaPhoneSquareAlt } from "react-icons/fa";
import {Tarifs} from "../../Components/Tarifs/Tarifs";
import {gallery, localisation} from '../../Components/Slider/gallery'
import Slider from '../../Components/Slider/Slider'

export interface ContactProps  {
	className: string;
	ariaHidden: boolean;
}

function Contact({className, ariaHidden}:ContactProps):ReactElement {

	return (

		
		<section className={`contact ${className}`} >


			<Title title="Contact"
					className="contact__title"
					ariaHidden={ariaHidden}
			/>

			<div className="contact__infos">

				<p tabIndex={ariaHidden ? -1 : 0}
					aria-hidden={ariaHidden}>Vous pouvez me contacter directement par téléphone en cliquant sur le lien suivant</p>

					<a className="contact__infos-tel"
						href="tel:+33685592292"
						aria-label="Bouton appeler"
						tabIndex={ariaHidden ? -1 : 0} >
						<FaPhoneSquareAlt/>
					</a>

				<p tabIndex={ariaHidden ? -1 : 0}
					aria-hidden={ariaHidden}>Ou en remplissant le formulaire ci-dessous. </p>
			</div>
			<ContactForm/>
			<Tarifs ariaHidden={ariaHidden}/>
			<Title title="Localisation"
					className="contact__place"
					ariaHidden={ariaHidden}
			/>
				<p tabIndex={ariaHidden ? -1 : 0}
					 className="contact__adress"
					aria-hidden={ariaHidden}>Centre équestre de Bellenoue, Lieu dit Bellenoue, 85180 Les Sables-d'Olonne </p>
			<Slider className={`main__content`} data={localisation}/>
;
		</section>
	)
};

export default Contact;
