// @flow
import * as React from 'react';
import Title from "../Title/Title";
import "./Tarifs.scss";


type Props = {

};
export const Tarifs = (ariaHidden) => {
  return (
    <>
    <Title title="Tarifs"
           className="tarifs"
           ariaHidden={ariaHidden}
    />

    <p className="tarifs__price" style={{marginTop: "20px"}} >Le tarif pour une séance individuelle est de 45 €.</p>
      <br/>
    <p className="tarifs__price" >Pour les séances collectives, n'hésitez pas à me contacter en remplissant le formulaire ci dessus.</p>
    </>
  );
};

